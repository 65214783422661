<template>
  <div class="book-detail">
    <div class="con-item book-info">
      <div class="container">
        <a-card class="img-width-style">
          <!-- <img alt="example" src="~@/assets/images/book-show.png" /> -->
          <img
            alt=""
            v-if="bookDetails.sysFldCoverpath"
            :src="bookDetails.imgurl"
          />
          <img
            slot="extra"
            alt="pic"
            v-if="!bookDetails.sysFldCoverpath"
            :src="require('@/assets/images/nobook.jpeg')"
          />
        </a-card>
        <a-descriptions>
          <template slot="title"> {{ bookDetails.name }} </template>
          <a-descriptions-item label="作者">
            <a v-for="(e, i) in bookDetails.authorList" :key="i">
              <span class="search-item" @click="onHandleSearch(e)">{{ e }}</span>
              <span v-if="i < bookDetails.authorList.length - 1">；</span>
            </a>
          </a-descriptions-item>
          <a-descriptions-item label="开本">
            {{ bookDetails.format }}
          </a-descriptions-item>
          <a-descriptions-item label="产品分类">
            {{ bookDetails.sysFldClassfication }}
          </a-descriptions-item>
          <a-descriptions-item label="责任编辑">
            <a v-for="(e, i) in bookDetails.executiveeditorList" :key="i">
              <span class="search-item" @click="onHandleSearch(e)">{{ e }}</span>
              <span v-if="i < bookDetails.executiveeditorList.length - 1">；</span>
            </a>
          </a-descriptions-item>
          <a-descriptions-item label="版次">
            {{ bookDetails.printnum }}
          </a-descriptions-item>
          <a-descriptions-item label="所在丛书">
            <span class="search-item" @click="onHandleSearch(bookDetails.seriesname)">{{ bookDetails.seriesname }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="文字编辑">
            <a v-for="(e, i) in bookDetails.ztcodeList" :key="i">
              <span class="search-item" @click="onHandleSearch(e)">{{ e }}</span>
              <span v-if="i < bookDetails.ztcodeList.length - 1">；</span>
            </a>
          </a-descriptions-item>
          <a-descriptions-item label="字数">
            {{ bookDetails.charcount }}
          </a-descriptions-item>
          <a-descriptions-item label="出版社">
            {{ bookDetails.issuedep }}
          </a-descriptions-item>
          <a-descriptions-item label="策划编辑">
            <a v-for="(e, i) in bookDetails.bookcodeList" :key="i">
              <span class="search-item" @click="onHandleSearch(e)">{{ e }}</span>
              <span v-if="i < bookDetails.bookcodeList.length - 1">；</span>
            </a>
          </a-descriptions-item>
          <a-descriptions-item label="装帧" :span="2">
            {{ bookDetails.bindingformat }}
          </a-descriptions-item>
          <a-descriptions-item label="ISBN">
            {{ bookDetails.isbn }}
          </a-descriptions-item>
          <a-descriptions-item label="印张">
            {{ bookDetails.sheets }}
          </a-descriptions-item>
          <a-descriptions-item label="纸质书价格">
            {{ bookDetails.price }}
          </a-descriptions-item>
          <a-descriptions-item label="微机代码">
            {{ bookDetails.department }}
          </a-descriptions-item>
          <a-descriptions-item label="出版时间">
            {{ bookDetails.issuedate }}
          </a-descriptions-item>
          <a-descriptions-item label="电子书价格">
            {{ bookDetails.eprice }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </div>
    <div class="con-item">
      <div class="container">
        <div class="relate-book">
          <h3><span>相关图书</span></h3>
          <a-carousel
            dotsClass="sleclet-class"
            v-if="databookList.length > 0"
          >
            <div v-for="(data, index) in databookList" :key="index">
              <a-list item-layout="vertical" :data-source="data.dataList">
                <a-list-item
                  slot="renderItem"
                  key="item.title"
                  slot-scope="item"
                >
                  <img @click="gobookDetails(item.sysFldDoi)" v-if="item.sysFldCoverpath" slot="extra" alt="" :src="item.imgurl" />
                  <img
                    slot="extra"
                    @click="gobookDetails(item.sysFldDoi)"
                    alt=""
                    v-if="!item.sysFldCoverpath"
                    :src="require('@/assets/images/nobook.jpeg')"
                  />
                  <a-list-item-meta style="margin-top:20px">
                    <a slot="title" class="nobook-name" :title="item.name" @click="gobookDetails(item.sysFldDoi)">{{ item.name }}</a>
                    <template slot="description">
                      <a-descriptions :column="1">
                        <a-descriptions-item label="作者">
                          {{ item.author }}
                        </a-descriptions-item>
                        <a-descriptions-item label="定价">
                          {{ item.price }}
                        </a-descriptions-item>
                      </a-descriptions>
                    </template>
                  </a-list-item-meta>
                  <div class="more" ><a-button @click="gobookDetails(item.sysFldDoi)">查看详情</a-button></div>
                </a-list-item>
              </a-list>
            </div>
          </a-carousel>
          <div v-if="databookList.length === 0" class="font-center">暂无相关数据</div>
        </div>
        <div class="right">
          <div class="brief">
            <h3><span>内容简介</span></h3>
            <div class="brief-content" v-if="bookDetails.digest" v-html="bookDetails.digest"></div>
            <div v-if="!bookDetails.digest" class="font-center">暂无</div>
          </div>
          <div class="author">
            <h3><span>作者介绍</span></h3>
            <div class="brief-content" v-html="bookDetails.authordesc"  v-if="bookDetails.authordesc"></div>
            <div v-if="!bookDetails.authordesc" class="font-center">暂无</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/book.js";
export default {
  name: "BookDetail",
  components: {},
  data() {
    return {
      bookDetails: {},
      autorDigst: "",
      relationBookList: [],
      databookList: [],
    };
  },
  created() {
    //获取图书详情
    this.getBookDetail();
    //查询相关图书
    this.getCorrelationBook();
  },
  methods: {
        //去图书详情
    gobookDetails(doi){
      this.$router.push({
        name:'BookDetail',
        params:{
          doi:doi
        }
      })
    },
    getCorrelationBook() {
      let obj = {
        pageSize: 1,
        pageNumber: 6,
        doi: this.$route.params.doi,
      };
      api.getCorrelationBook(obj).then((res) => {
        if (res.data.success) {
          this.databookList = [];
          this.relationBookList = res.data.data.list;
          this.relationBookList.map(function (item) {
            item.imgurl =
              process.env.VUE_APP_API_BASE_URL +
              "/download?fileCode=" +
              item.sysFldCoverpath;
          });
          if (
            this.relationBookList.length &&
            this.relationBookList.length > 0
          ) {
            let obj = {
              dataList: this.relationBookList.slice(0, 2),
            };
            this.databookList.push(obj);
          }
          if (
            this.relationBookList.length &&
            this.relationBookList.length > 2
          ) {
            let obj = {
              dataList: this.relationBookList.slice(2, 4),
            };
            this.databookList.push(obj);
          }
          if (
            this.relationBookList.length &&
            this.relationBookList.length > 4
          ) {
            let obj = {
              dataList: this.relationBookList.slice(4, 6),
            };
            this.databookList.push(obj);
          }
          console.log(this.databookList,'66');
          
        } else {
          this.relationBookList = [];
        }
      });
    },
    getBookDetail() {
      api.getBookDetail(this.$route.params.doi).then((res) => {
        if (res.data.success) {
          this.bookDetails = res.data.data;

          let author = this.bookDetails.author
          this.bookDetails.authorList = author ? author.split(';') : []
          let executiveeditor = this.bookDetails.executiveeditor
          this.bookDetails.executiveeditorList = executiveeditor ? executiveeditor.split(';') : []
          let bookcode = this.bookDetails.bookcode
          this.bookDetails.bookcodeList = bookcode ? bookcode.split(';') : []
          let ztcode = this.bookDetails.ztcode
          this.bookDetails.ztcodeList = ztcode ? ztcode.split(';') : []

          this.bookDetails.imgurl =
            process.env.VUE_APP_API_BASE_URL +
            "/download?fileCode=" +
            this.bookDetails.sysFldCoverpath;
          // this.autorDigst = res.data.map.editorInfo
          //   ? res.data.map.editorInfo.digest
          //   : "";
        } else {
          this.bookDetails = {};
          // this.autorDigst = "";
        }
      });
    },
    onHandleSearch(keyWord) {
      this.$router.push({
        name: "BookList",
        params: {
          value: keyWord,
        },
      });
    }
  },
};
</script>
<style lang="less">
@import "./BookDetail.less";
</style>
